import { useDebounceFn } from '@vueuse/core';

import { ref, watch, nextTick } from 'vue';
export default {
  name: 'VirtualScroll',
  props: {
    itemHeight: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    visibleCount: {
      type: Number,
      required: true
    },
    styleObj: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:startIndex'],
  setup(props, {
    emit
  }) {
    const totalHeight = ref(0);
    const visibleItems = ref([]);
    const offsetY = ref(0);
    const startIndex = ref(0);
    const endIndex = ref(0);
    const currentBufferZone = ref(null);
    const bufferZoneHeight = 20;
    const scrollWrapper = ref(null);
    const visibleList = ref(null);

    // 使用防抖优化滚动事件处理
    const handleScroll = useDebounceFn(() => {
      updateVisibleItems();
      emit('update:startIndex', startIndex.value);
    }, 16); // 约60fps的刷新率

    // 优化可见项计算逻辑
    const updateVisibleItems = () => {
      var _scrollWrapper$value;
      const scrollTop = (_scrollWrapper$value = scrollWrapper.value) === null || _scrollWrapper$value === void 0 ? void 0 : _scrollWrapper$value.scrollTop;

      // 使用位运算代替Math.floor提升性能
      const startIndexValue = scrollTop / props.itemHeight >> 0;
      const endIndexValue = Math.min(startIndexValue + props.visibleCount, props.items.length);

      // 优化缓冲区判断逻辑
      if (shouldUpdateItems(startIndexValue, endIndexValue, scrollTop)) {
        // 使用数组切片替代循环
        visibleItems.value = props.items.slice(startIndexValue, endIndexValue);
        offsetY.value = startIndexValue * props.itemHeight;
        updateBufferZone(startIndexValue, endIndexValue);
        startIndex.value = startIndexValue;
        endIndex.value = endIndexValue;
      }
    };

    // 抽取缓冲区更新逻辑
    const updateBufferZone = (start, end) => {
      const bufferZoneTop = Math.max(0, start - bufferZoneHeight);
      const bufferZoneBottom = Math.min(end + bufferZoneHeight, props.items.length);
      currentBufferZone.value = {
        top: bufferZoneTop * props.itemHeight,
        bottom: bufferZoneBottom * props.itemHeight
      };
    };

    // 优化更新判断逻辑
    const shouldUpdateItems = (start, end, scrollTop) => {
      const bufferHeight = bufferZoneHeight * props.itemHeight;
      const bufferTop = scrollTop - bufferHeight;
      const bufferBottom = scrollTop + props.visibleCount * props.itemHeight + bufferHeight;
      return start !== startIndex.value || end !== endIndex.value || !currentBufferZone.value || bufferTop < currentBufferZone.value.top || bufferBottom > currentBufferZone.value.bottom;
    };

    // 计算列表总高度
    const updateTotalHeight = () => {
      totalHeight.value = props.itemHeight * props.items.length;
    };

    // 监听 props 的变化，更新列表总高度和可见区域的列表项
    watch(() => props.items, () => {
      updateTotalHeight();
      updateVisibleItems();
    }, {
      immediate: true
    });
    watch(() => props.visibleCount, () => {
      nextTick(() => {
        updateVisibleItems();
      });
    }, {
      immediate: true
    });
    return {
      totalHeight,
      visibleItems,
      offsetY,
      startIndex,
      scrollWrapper,
      visibleList,
      handleScroll,
      updateVisibleItems,
      updateTotalHeight
    };
  }
};